import firebase from 'firebase'
// Initialize Firebase
var config = {
  apiKey: "AIzaSyAGVvWDKeB3gQf_tfuLG8aBrzxLgJJwyvE",
  authDomain: "grabthatspot-1a2ed.firebaseapp.com",
  databaseURL: "https://grabthatspot-1a2ed.firebaseio.com",
  projectId: "grabthatspot-1a2ed",
  storageBucket: "grabthatspot-1a2ed.appspot.com",
  messagingSenderId: "231775446200"
};
export default firebase.initializeApp(config);
